import {Link} from '@remix-run/react';
import {Image, Typography} from '~/components/ui';
import { cn } from '~/lib/utils';

import ecodrive from '~/assets/icons/ecodrive.png';
import ecodriveWhite from '~/assets/icons/ecodriveWhite.png';

interface Props {
    className?: string;
    dark?: boolean;
    mainText: string;
    subtitle?: boolean;
    subtitleText?: string;
    onClick?: () => void;
  }

export const EcodriveButton = (props: Props) => {
    return (
        <Link to='/sustainability' className={
            cn(`p-2 inline-flex items-center justify-center rounded-md border ring-offset-background transition-colors`, props.className, props.dark ? 'bg-eco' : 'bg-white')
            } onClick={props.onClick}>
        <div className='flex-col'>
          <div className='flex flex-row'>
            <Image
                src={props.dark ? ecodriveWhite : ecodrive}
                alt=""
                height="auto"
                width=""
                className="w-4 mr-4 align-top"
            />
            <Typography variant="button" className={props.dark ? "text-white" : "text-eco"}>{props.mainText}</Typography>
          </div>
          {props.subtitle && <div className='flex-row items-center text-center'>
            <Image
                src={props.dark ? ecodriveWhite : ecodrive}
                alt=""
                height="auto"
                width=""
                className="h-4 mr-2 inline"
            />
            <Typography variant="button" className={`text-xs text-eco inline ${props.dark ? "text-white" : "text-eco"}`} >{props.subtitleText}</Typography>
          </div>}
        </div>
        </Link>
      )
}