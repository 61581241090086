import {Minus, Plus} from 'lucide-react';
import {Button, Typography} from '../ui';
import {cn} from '~/lib/utils';
import {CartLineUpdateButton} from '../Cart';
import { Attribute, AttributeInput, InputMaybe} from '@shopify/hydrogen/storefront-api-types';

interface Props {
  value: number;
  className?: string;
  fullSize?: boolean;
  iconSize?: number;
  onAddItem?: () => void;
  onRemoveItem?: () => void;
  maxQuantity?: number;
}

export const ProductCounter = (props: Props) => {
  const {className, fullSize, iconSize, onAddItem, onRemoveItem} = props;
  let maxQuantity = props.maxQuantity === undefined ? 1: props.maxQuantity;

  const height = fullSize ? 'h-auto' : 'h-10 sm:h-10';
  return (
    <div
      className={cn(
        'flex w-28 rounded-lg overflow-hidden',
        `${height}`,
        className,
      )}
    >
      <Button
        variant="cool"
        className="p-0 rounded-none w-[25px] h-full"
        onClick={onRemoveItem}
        disabled={props.value <= 1}
      >
        <Minus size={iconSize || 15} color="white" strokeWidth={5} />
      </Button>
      <div className="bg-silver/20 relative flex-grow">
        <Typography
          variant="headline6"
          className="h-full select-none flex justify-center items-center "
        >
          {props.value}
        </Typography>
      </div>
      <Button
        variant="cool"
        className="p-0 rounded-none w-[25px] h-full"
        onClick={onAddItem}
        disabled={props.value >= maxQuantity}
      >
        <Plus size={iconSize || 15} color="white" strokeWidth={5} />
      </Button>
    </div>
  );
};

interface CartCounterProps {
  value: number;
  className?: string;
  fullSize?: boolean;
  iconSize?: number;
  lineId: string;
  prevQuantity?: number;
  nextQuantity?: number;
  disabled?: boolean;
  productAttributes: Pick<Attribute, "value" | "key">[];
}

export const ProductCounterCart = (props: CartCounterProps) => {
  const {
    className,
    fullSize,
    iconSize,
    lineId,
    prevQuantity,
    nextQuantity,
    value,
    disabled, 
    productAttributes
  } = props;
  const height = fullSize ? 'h-auto' : 'h-8 ';
  let maxQuantity: number = 1;
  let attributes: InputMaybe<AttributeInput[]> = [];
  productAttributes.map((attr) => {
    attributes?.push( { key: attr.key, value: attr.value ?? "" } );
    if(attr.key === "MAXQUANTITY"){
      maxQuantity = parseInt(attr.value?? "1");
    }
  });

  return (
    <div
      className={cn(
        'flex w-24 sm:w-28 rounded-md overflow-hidden',
        `${height}`,
        className,
      )}
    >
      <CartLineUpdateButton lines={[{id: lineId, quantity: prevQuantity, attributes: attributes}]}>
        <Button
          variant="cool"
          className="p-2 rounded-none h-auto"
          disabled={value <= 1 || disabled}
          value={prevQuantity}
        >
          <Minus size={iconSize || 15} color="white" strokeWidth={5} />
        </Button>
      </CartLineUpdateButton>
      <div className="bg-silver/20 relative flex-grow">
        <Typography
          variant="headline6"
          className="text-center select-none absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        >
          {props.value}
        </Typography>
      </div>
      <CartLineUpdateButton lines={[{id: lineId, quantity: nextQuantity, attributes: attributes}]}>
        <Button
          variant="cool"
          className="p-2 rounded-none h-auto"
          value={nextQuantity}
          disabled={value >= maxQuantity || disabled}
        >
          <Plus size={iconSize || 15} color="white" strokeWidth={5} />
        </Button>
      </CartLineUpdateButton>
    </div>
  );
};
