import { CartForm } from '@shopify/hydrogen';
import type { CartLineUpdateInput } from '@shopify/hydrogen/storefront-api-types';
import { Link } from '@remix-run/react';
import type { CartApiQueryFragment } from 'storefrontapi.generated';
import { useVariantUrl } from '~/utils';
import { cn } from '~/lib/utils';
import { Button, EcodriveButton, Image, Typography } from './ui';
import { Trash2 } from 'lucide-react';
import { ProductCounterCart } from './products';
import { CustomMoney } from './ui/Money.component';
import placeholder from '~/assets/images/placeholder.png';
import { forwardRef } from 'react';

type CartLine = CartApiQueryFragment['lines']['nodes'][0];

type CartMainProps = {
  cart: CartApiQueryFragment | null;
  layout: 'page' | 'aside';
  minimumPurchase: number | 1;
};

export const CartMain = forwardRef<HTMLDivElement, CartMainProps>(
  ({ layout, cart, minimumPurchase }, ref) => {
    const linesCount = Boolean(cart?.lines?.nodes?.length || 0);
    const withDiscount =
      cart &&
      Boolean(cart.discountCodes.filter((code) => code.applicable).length);

    return (
      <div className={'cart-main'} ref={ref}>
        <CartEmpty hidden={linesCount} layout={layout} />
        <CartDetails cart={cart} layout={layout} minimumPurchase={minimumPurchase} />
      </div>
    );
  }
);

function CartDetails({ layout, cart, minimumPurchase }: CartMainProps) {
  const cartHasItems = !!cart && cart.totalQuantity > 0;

  return (
    <div className="cart-details">
      <CartLines lines={cart?.lines} layout={layout} />
      {cartHasItems && (
        <CartSummary cost={cart.cost} layout={layout}>
          {/* <CartDiscounts discountCodes={cart.discountCodes} /> */}
          <CartCheckoutActions checkoutUrl={cart.checkoutUrl} cost={cart.cost} minimumPurchase={minimumPurchase} />
        </CartSummary>
      )}
    </div>
  );
}

function CartLines({
  lines,
  layout,
}: {
  layout: CartMainProps['layout'];
  lines: CartApiQueryFragment['lines'] | undefined;
}) {
  if (!lines) return null;

  return (
    <div aria-labelledby="cart-lines" className="cart-lines 2xl:pr-32 xl:pr-8 lg:pr-8 pr-1">
      <ul>
        {lines.nodes.map((line) => (
          <CartLineItem key={line.id} line={line} layout={layout} />
        ))}
      </ul>
    </div>
  );
}

function CartLineItem({
  layout,
  line
}: {
  layout: CartMainProps['layout'];
  line: CartLine;
}) {
  const { id, merchandise, attributes } = line;
  const { product, title, image, selectedOptions } = merchandise;
  const lineItemUrl = useVariantUrl(product.handle, selectedOptions);

  const productoDescription = attributes.filter((attr) => attr.key === "DESCRIPTION");

  return (
    <li key={id} className="cart-line w-full border-b-2 border-b-shoppingcartSilver">
      <div className="flex grid-cols-2 gap-4 w-full mb-10 mt-6">
        <div className="col-span-1 h-40 w-60">
          {image && (
            <Image src={image.url || placeholder} alt={title} className="rounded-lg sm:w-40 w-60 sm:h-40 h-64"></Image>
          )}
          {!image &&
            <Image src={placeholder} alt={title} className="rounded-lg sm:w-40 w-60 sm:h-40 h-64"></Image>
          }
        </div>
        <div className="sm:flex col-span-1 w-full">
          <div className="sm:flex sm:6/12 w-full mb-3">
            <div className="w-full pr-3">
              <Link
                prefetch="intent"
                to={lineItemUrl}
                onClick={() => {
                  if (layout === 'aside') {
                    // close the drawer
                    window.location.href = lineItemUrl;
                  }
                }}
              >
                <Typography variant="headline6" className='pt-1 mb-2 line-clamp-2 text-ellipsis overflow-hidden'>
                  {product.title}
                </Typography>
              </Link>
              <Typography variant="body2" className='line-clamp-3 text-ellipsis'>
                {productoDescription.length > 0 && productoDescription[0].value}
              </Typography>
            </div>
          </div>
          <div className="sm:flex sm:3/12 w-full">
            <div className='text-left sm:w-6/12 w-full mb-1'>
              <CartLineQuantity line={line} />
            </div>
            <div className='sm:text-right sm:w-6/12 w-full text-left'>
              <Typography variant="headline6">
                <CartLinePrice line={line} as="span" />
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

function CartCheckoutActions(
  {
    checkoutUrl,
    cost,
    minimumPurchase
  }: {
    checkoutUrl: string;
    cost: CartApiQueryFragment['cost'];
    minimumPurchase: number;
  }) {
  if (!checkoutUrl) return null;
  const checkoutAvailable = parseFloat(cost.totalAmount.amount) >= minimumPurchase;

  return (
    <div className="sm:w-8/12 w-full py-4 right-0 sm:float-right">
      <Button variant="cool" className={`w-full mb-4 p-6 `} disabled={!checkoutAvailable} onClick={() => window.location.href = checkoutUrl}>
        <Typography variant="button">Checkout</Typography>
      </Button>
      {!checkoutAvailable &&
        <Typography variant="body2" className="text-center mb-4">Your cart must total ${minimumPurchase} to checkout.</Typography>
      }
      <Button variant="cool" className="w-full mb-4 p-6 bg-black" to={"/"}>
        <Typography variant="button">Continue shopping</Typography>
      </Button>
      <Typography variant="body2" className="text-center">Sales tax and discount codes are calculated at checkout</Typography>
      <EcodriveButton mainText='EVERY ORDER PLANTS 1 TREE' subtitle subtitleText='ecodrive' className='w-full' />
    </div>
  );
}

export function CartSummary({
  cost,
  layout,
  children = null,
}: {
  children?: React.ReactNode;
  cost: CartApiQueryFragment['cost'];
  layout: CartMainProps['layout'];
}) {
  const className =
    layout === 'page' ? 'cart-summary-page' : 'cart-summary-aside';

  const emptyTotal = { currencyCode: "USD", amount: "0.0" };

  return (
    <>
      <div aria-labelledby="cart-summary" className={cn("w-full sm:flex", className)}>
        <div className='flex-none sm:w-4/12 w-full'></div>
        <div className='flex-none sm:w-8/12 w-full 2xl:pr-32 lg:pr-8 md:pr-4 pr-2'>
          <div className="grid grid-cols-2 gap-2 w-full">
            <div className="col-span-1"><Typography variant="body1">Subtotal:</Typography></div>
            <div className="col-span-1 items-end text-right">
              <CustomMoney data={cost?.subtotalAmount ?? emptyTotal} CurrencySymbolWithTrailingZeros showCurrencyCode />
            </div>
            {/*
            TODO: this part will be calculated in shopify
            <div className="col-span-1"><Typography variant="body1">Sales Tax:</Typography></div>
            <div className="col-span-1 items-end text-right">
              <CustomMoney data={cost?.totalTaxAmount ?? emptyTotal} />
            </div> */}
            <div className="col-span-1">
              <Typography variant="headline5">TOTAL:</Typography>
            </div>
            <div className="col-span-1 items-end text-right">
              <Typography variant="headline5"><CustomMoney data={cost?.totalAmount ?? emptyTotal} CurrencySymbolWithTrailingZeros showCurrencyCode /></Typography>
            </div>
          </div>
          <div className='w-full items-end text-right justify-end'>
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

function CartLineRemoveButton({ lineIds }: { lineIds: string[] }) {
  return (
    <CartForm
      route="/cart"
      action={CartForm.ACTIONS.LinesRemove}
      inputs={{ lineIds }}
    >
      <Button size="icon" variant="linkCoolBlue" type="submit" className="mt-[0px] h-8"><Trash2 /></Button>
    </CartForm>
  );
}

function CartLineQuantity({ line }: { line: CartLine }) {
  if (!line || typeof line?.quantity === 'undefined') return null;
  const { id: lineId, quantity, attributes } = line;
  const prevQuantity = Number(Math.max(0, quantity - 1).toFixed(0));
  const nextQuantity = Number((quantity + 1).toFixed(0));

  return (
    <div className="cart-line-quantiy">
      <ProductCounterCart value={quantity} className="mt-0" lineId={lineId} nextQuantity={nextQuantity} prevQuantity={prevQuantity} productAttributes={attributes} />
      <CartLineRemoveButton lineIds={[lineId]} />
    </div>
  );
}

function CartLinePrice({
  line,
  priceType = 'regular' }: {
    line: CartLine;
    priceType?: 'regular' | 'compareAt';
    [key: string]: any;
  }) {
  if (!line?.cost?.amountPerQuantity || !line?.cost?.totalAmount) return null;

  const moneyV2 =
    priceType === 'regular'
      ? line.cost.totalAmount
      : line.cost.compareAtAmountPerQuantity;

  if (moneyV2 == null) {
    return null;
  }

  return (
    <div className="pt-1">
      <CustomMoney data={moneyV2} CurrencySymbolWithTrailingZeros showCurrencyCode></CustomMoney>
    </div>
  );
}

export function CartEmpty({
  hidden = false,
  layout = 'aside',
}: {
  hidden: boolean;
  layout?: CartMainProps['layout'];
}) {
  return (
    <div hidden={hidden}>
      <br />
      <Typography variant="body2">
        Looks like you haven&rsquo;t added anything yet, let&rsquo;s get you
        started!
      </Typography>
      <br></br>
      <Button variant="primary" to="/">
        <Typography variant="button">Continue shopping</Typography>
      </Button>
    </div>
  );
}


function UpdateDiscountForm({
  discountCodes,
  children,
}: {
  discountCodes?: string[];
  children: React.ReactNode;
}) {
  return (
    <CartForm
      route="/cart"
      action={CartForm.ACTIONS.DiscountCodesUpdate}
      inputs={{
        discountCodes: discountCodes || [],
      }}
    >
      {children}
    </CartForm>
  );
}

export function CartLineUpdateButton({
  children,
  lines,
}: {
  children: React.ReactNode;
  lines: CartLineUpdateInput[];
}) {
  return (
    <CartForm
      route="/cart"
      action={CartForm.ACTIONS.LinesUpdate}
      inputs={{ lines }}
    >
      {children}
    </CartForm>
  );
}
